import React, { useState, useContext, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../utils/styles.css"
import { useForm } from "react-hook-form"
import { ThemeContext } from "../context/themeContext"
import { RoomContext } from "../context/roomCreatorContext"
import scrollTo from "gatsby-plugin-smoothscroll"

const DiceIndex = ({ data, location }) => {
  const siteTitle = "Roll Funky Dice!"
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const { setRoomCreator } = useContext(RoomContext)

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Roll Funky Dice!" />
      <div className="container text-input-text relative grid mx-auto px-4 sm:px-6 lg:px-8 py-32">
        <div className="space-y-4">
          <p className="max-w-3xl font-extrabold text-2xl mx-auto rounded-md font-happydayPro">
            {" "}
            <button
              className="bg-button-bg text-primary-text text-xl ml-1 p-1 font-bold rounded-md"
              onClick={() => {
                scrollTo("#roomTop")
                setRoomCreator(true)
              }}
            >
              Create a room
            </button>{" "}
            Roll dice in real time with your adventuring party!
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default DiceIndex
